import type {
  Bank,
  UserBank,
  ProvidusTransferParams,
  PaymentOption,
} from '~/types/payment'

export const usePaymentStore = defineStore('paymentStore', () => {
  const banks = ref<Bank[] | null>(null)
  const savedBanks = ref<UserBank[] | null>(null)
  const isBalanceVisible = ref(localStorage.getItem('showBalance'))
  const providusTransferPayload = ref<ProvidusTransferParams | null>(null)
  const paymentOptions = ref<PaymentOption[] | null>(null)

  function toggleBalanceVisibility(status: boolean) {
    if (status) {
      isBalanceVisible.value = 'false'
      localStorage.setItem('showBalance', 'false')
    } else {
      isBalanceVisible.value = 'true'
      localStorage.setItem('showBalance', 'true')
    }
  }
  const showBalance = computed((): boolean => {
    return isBalanceVisible.value === null
      ? false
      : JSON.parse(isBalanceVisible.value)
  })

  return {
    banks,
    savedBanks,
    isBalanceVisible,
    toggleBalanceVisibility,
    showBalance,
    providusTransferPayload,
    paymentOptions,
  }
})
